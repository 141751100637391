import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
      authenticated: 'pending',
      bearer: "F15ziTD9TGgp7lgWzbptFmvYkO40QzWY3tB1Kmt9uC/aOWZ+7aznX1HK3jLri+Hbbp6Ip2cI7J5SG+VKfX4SXpv+3noUVaeY6+/EhlBOYYU9V4EOFkwOGPawgCadXtHYA/gsv3ctY1FsE3ESu8T0UNzCfu+JcYxWR8u4ILTcfqCA8yoh1Ns3PGIRXFVnSvdQLadLwvhvvEFkTcwHH1pkklGN377scM6L34oR3y7/aCNosreKtyuukWY3eLPosWOz3ghU/Sk2BJO2FmLoTeCWqLPvtgl4UuXhdBYGAsiPi06fnnVYl//PQAi490voRtUuDRTdPpttbWb+ETM/+7qvPQ=="
  },
  reducers: {
    setAuthentication: (state, auth) => {
        state.authenticated = auth.payload
    },
    setUser: (state, user) => {
        // const newState = {
        //     authenticated: true, 
        //     ...user.payload
        // }

          return user.payload
    },
    clearUser: () => {
      console.log('here')
      console.log(Object.keys(sessionStorage))
      for (let i = 0; i < sessionStorage.length; i++) {
        console.log(sessionStorage.key(i))
        sessionStorage.removeItem(sessionStorage.key(i))
      }
      // sessionStorage.removeItem('persist:root')
      //   return {
      //     authenticated: 'pending',
      //     bearer: "TkQ6kYJB3Prcgq8WBJjv2XJijJM9SNAONNYPMGVgOkHZGbCc1D+rxh8Pa7NMee/1q0zKqZy6WJAjqxuYBMwBxwo13gFiSDG0ZoLWanfB5nLbm/TOlO3QC5HYrrF5A1AQ7/o83zaCKuBA7nSY1UMJjAqHqK7a74YGb71V0PQJT6l4hcEklPJWpD9o0+4KNasdSK7wwM9gtePQWze5prR+Ff1VAM9WpaSlfi+6oYZwE/ZAApt/WJKDW2UWTcuCXNDiLqswAgwz4JSZhMd5KNCSJCPCfNJh7FucVZoD2Xv1FxxJ0h6oPrIH/2BXEoDN3RKLuAMOQhke5CFATxmrjHszxQ=="
      // }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuthentication, setUser, clearUser } = loginSlice.actions

export default loginSlice.reducer